import 'normalize.css';
import 'radar-chart-d3/src/radar-chart.css';
import './../css/style.scss';
import * as d3 from 'd3';

// const RadarChart = require('radar-chart-d3/src/radar-chart.js');
const RadarChart = require('commons/radar-chart.js');

const app = (function() {
    // Article card click
    document.querySelectorAll('.js-clickcard').forEach(item => {
        // item.style.cursor = 'pointer';
        item.addEventListener('click', e => {
            let link = item.querySelector('a');
            if (link !== e.target) {
                link.click();
            }
        });
    });

    document.querySelectorAll('.horzintal-filter-menu .menu-item a').forEach(item => {
        var label = item.innerHTML;
        item.innerHTML = `<span class="label">${label}</span><span class="label-clone" aria-hidden="true">${label}</span>`;
    });

    var tasteChart = document.getElementById('taste-chart');
    var aroma, finish, dry, body, fruity, bitter, sweet, sour = 0;

    if (tasteChart) {
        aroma = tasteChart.dataset.aroma;
        finish = tasteChart.dataset.finish;
        dry = tasteChart.dataset.dry;
        body = tasteChart.dataset.body;
        fruity = tasteChart.dataset.fruity;
        bitter = tasteChart.dataset.bitter;
        sweet = tasteChart.dataset.sweet;
        sour = tasteChart.dataset.sour;

        const data = [
            {
            className: 'taste', // optional, can be used for styling
            axes: [
                {axis: "aroma", value: aroma, yOffset: 8 },
                {axis: "nasmaak", value: finish, xOffset: -34 },
                {axis: "droog", value: dry, xOffset: -10},  
                {axis: "body", value: body},  
                {axis: "fruitig", value: fruity, yOffset: -8},
                {axis: "bitter", value: bitter},
                {axis: "zoet", value: sweet, xOffset: 4},
                {axis: "zuur", value: sour, xOffset: 4}
            ]
            },
        ];

        const mycfg = {
            color: () => '#000',
            circles: false,
            levels: 4,
            factor: 0.68,
            factorLegend: 0.95,
            w: 280,
            h: 250,
            maxValue: 4,
            minValue: 0,
            tooltipFormatClass: null
        }

        RadarChart.draw("#taste-chart", data, mycfg);
    }

    // ********
    // Filter
    // ********
    var multi_select = document.querySelectorAll('.multi-select-dropdown');

    for (let i = 0; i < multi_select.length; i++) {
        var elm = multi_select[i];
        elm.addEventListener('click', listboxEvent, false);

        function listboxEvent(evt) {
            if (evt.target.matches('.exp_button')) {
                evt.preventDefault();

                var button = evt.target;
                var container = evt.target.parentElement;
                var list = container.querySelector('.exp_elem_list');

                // Hide all multiselects
                hideAllMultiSelect(container);    

                // Turn off/on multi-select
                var list_hidden = list.classList.toggle('hidden');
                button.classList.toggle('is-active');
                button.setAttribute('aria-expanded', !list_hidden);
            }
        }
    }

    function hideAllMultiSelect(currentElm) {
        for (let i = 0; i < multi_select.length; i++) {
            var elm = multi_select[i];
            if (elm != currentElm) {
                elm.classList.remove('is-active');
                elm.querySelector('.exp_elem_list').classList.add('hidden');
                elm.querySelector('.exp_button').setAttribute('aria-expanded', false);
            }
        }
    }

    // Filter nav
    var filter_button = document.getElementById('filter_button');
    var filter_nav_network = document.getElementById('filter-nav-network');

    if (filter_button && filter_nav_network) {
        filter_button.addEventListener('click', filterButtonEvent);
    }

    function filterButtonEvent(evt) {
        var isExpanded = filter_nav_network.getAttribute('aria-hidden') === 'false';
        filter_nav_network.setAttribute('aria-hidden', isExpanded);
        filter_button.setAttribute('aria-expanded', !isExpanded);
    }
})();